
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// SimpleMDE, TODO: only import this on pages that need it
@import '~easymde/dist/easymde.min.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

// Show pointer cursor for button list group items
.list-group-item-action {
  cursor: pointer;
}

.strong {
  font-weight: bold !important;
}

.a {
  color: $link-color;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.a:hover {
  color: $link-hover-color;
  text-decoration: underline;
}

// Flash message wrapper
// NOTE: using padding here will interfer with the navigation menu
.flash-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.flash-wrapper > .alert {
  margin: 1rem;
}

// cursor-pointer utility class
.cursor-pointer {
  cursor: pointer !important;
}

// Limit logo height
.navbar-brand img {
  height: 2rem;
  margin-right: .25rem;
}

// Display speaker point inputs inline
.form-control.speaker-point-input {
  display: inline-block;
  height: 2.25rem;
  width: 3.5rem;
  margin-right: 0.125rem;
  padding: 0.5rem;
}

// Judge list
.judge-list-none {
  background-color: $judge-list-none !important;
}

.judge-list-low {
  background-color: $judge-list-low !important;
}

.judge-list-low td,
.judge-list-medium td,
.judge-list-high td
{
  border-color: white !important;
}

.judge-list-none td:first-child,
.judge-list-low td:first-child,
.judge-list-medium td:first-child,
.judge-list-high td:first-child {
  border-left-width: 0 !important;
}

.judge-list-none td:first-child,
.judge-list-low td:last-child,
.judge-list-medium td:last-child,
.judge-list-high td:last-child {
  border-right-width: 0 !important;
}

.judge-list-medium {
  background-color: $judge-list-medium !important;
}

.judge-list-high {
  background-color: $judge-list-high !important;
}

.judge-list-none a,
.judge-list-low a,
.judge-list-medium a,
.judge-list-high a {
  color: $black;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0.2);
}

.judge-checkbox-label {
  padding: 0.75rem;
}

// Prevent RFDs from becoming illegible
p {
  max-width: 35rem;
}

// Remove left and right borders from tables
.table-flush {
  border: 0 !important;
  margin: 0;
}

.table-flush th:first-child,
.table-flush td:first-child {
  border-left: 0 !important;
}

.table-flush th:last-child,
.table-flush td:last-child {
  border-right: 0 !important;
}

.table-flush tr:last-child td {
  border-bottom: 0;
}

.time-of-day {
  text-align: center;
  height: 1.5rem;
}

/* Inline Middot List */

.list-middot {
  padding: 0;
  margin: 0;
  list-style: none;
}

.list-middot-item {
  display: inline-block;
}

.list-middot-item:not(:last-child)::after {
  content: "\00b7";
  color: #767676;
  padding-left: 0.5em;
  padding-right: 0.25em;
}

.profile-picture {
  border-radius: 50% !important;
  object-fit: cover;
  object-position: 50% 50%;
  background-color: #FEFEFE;
}

.profile-picture-large {
  width: 175px;
  height: 175px;
}

.profile-picture-small {
  width: 50px;
  height: 50px;
}

.profile-picture-tiny {
  width: 2.5rem;
  height: 2.5rem;
}