
// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #000000;

// Judge List Colors
$judge-list-none: transparent;
$judge-list-low: rgb(249, 249, 55, 0.2); // 1-3 rounds
$judge-list-medium: rgb(252, 144, 57, 0.2); // 4-7 rounds
$judge-list-high: rgb(251, 21, 58, 0.2); // 8+ rounds